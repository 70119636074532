import { axiosClient } from "@/api/axios";

export function getStudentUserInfo() {
  return axiosClient.get("/dashboard/student/user-info");
}

export function getStudentProgressInfo() {
  return axiosClient.get("/dashboard/student/progress-info");
}

export function getStudentSemesterInfo() {
  return axiosClient.get("/dashboard/student/semester-info");
}

export function getStudentLatestTopics() {
  return axiosClient.get("/dashboard/student/latest-topics");
}

export function getStudentCoursesInfo() {
  return axiosClient.get("/dashboard/student/courses-info");
}

export function getStudentRankingInfo() {
  return axiosClient.get("/dashboard/student/ranking-info");
}
