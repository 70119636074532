import { LessonApi } from "@/api";
import { showSuccessSnackbar } from "@/support/snackbar";

export async function getLesson(id) {
  const { data } = await LessonApi.getLesson(id);
  return data;
}

export async function getAllLessons() {
  const { data } = await LessonApi.getAllLessons();
  return data;
}

export async function getLessonInfo(lessonId) {
  const { data } = await LessonApi.getLessonInfo(lessonId);
  return data;
}

export async function saveLessonInfo(lessonId, lessonInfo) {
  const response = await LessonApi.saveLessonInfo(lessonId, lessonInfo);
  showSuccessSnackbar({
    code: response.status,
    message: "Lesson info saved successfully",
  });
}

export async function deleteLesson(lessonId, lessonName) {
  const response = await LessonApi.deleteLesson(lessonId);
  showSuccessSnackbar({
    code: response.status,
    message: `Successfully removed lesson: ${lessonName}`,
  });
}

export async function getQuestions(lessonId) {
  const { data } = await LessonApi.getQuestions(lessonId);
  return data;
}

export async function saveQuestions(lessonId, questions) {
  await LessonApi.saveQuestions(lessonId, questions);
}
