import store from "@/store";

const SNACKBAR_DEFAULT_TIMEOUT = 5000;
const SNACKBAR_DEFAULT_ERROR_COLOR = "error";
const SNACKBAR_DEFAULT_SUCCESS_COLOR = "success";
const SNACKBAR_DEFAULT_INFO_COLOR = "info";
const SNACKBAR_DEFAULT_WARNING_COLOR = "warning";

function snackbarMapError(err) {
  let timeout = SNACKBAR_DEFAULT_TIMEOUT;
  let color = SNACKBAR_DEFAULT_ERROR_COLOR;
  let text = "An error occurred.";
  let code = undefined;
  if (err.response) {
    switch (err.response.status) {
      case 400:
        text = err.response.data.message;
        break;
      case 401:
        text =
          "You are not authorized for the requested operation, please login again.";
        break;
      case 403:
        text = "You don't have permissions for the requested operation.";
        break;
      case 404:
        text = "Resource not found, please contact the administrator.";
        break;
      case 413:
        text = "Payload too large, please contact the administrator.";
        break;
      default:
        text = "An error occurred, please try later.";
        break;
    }
    code = err.response.status;
  }

  let payload = {
    timeout: timeout,
    color: color,
    message: text,
    code: code,
  };
  return payload;
}

function snackbarSuccessPayload(code, message) {
  let payload = {
    timeout: SNACKBAR_DEFAULT_TIMEOUT,
    color: SNACKBAR_DEFAULT_SUCCESS_COLOR,
    message: message,
    code: code,
  };
  return payload;
}

function showSnackbar(code, message, timeout, color) {
  let payload = {
    timeout: timeout,
    color: color,
    message: message,
    code: code,
  };
  store.dispatch("snackbar/showSnackbar", payload);
}

function showSuccessSnackbar({
  code,
  message,
  timeout = SNACKBAR_DEFAULT_TIMEOUT,
}) {
  showSnackbar(code, message, timeout, SNACKBAR_DEFAULT_SUCCESS_COLOR);
}

function showErrorSnackbar({
  code,
  message,
  timeout = SNACKBAR_DEFAULT_TIMEOUT,
}) {
  showSnackbar(code, message, timeout, SNACKBAR_DEFAULT_ERROR_COLOR);
}

function showWarningSnackbar({
  code,
  message,
  timeout = SNACKBAR_DEFAULT_TIMEOUT,
}) {
  showSnackbar(code, message, timeout, SNACKBAR_DEFAULT_WARNING_COLOR);
}

function showInfoSnackbar({
  code,
  message,
  timeout = SNACKBAR_DEFAULT_TIMEOUT,
}) {
  showSnackbar(code, message, timeout, SNACKBAR_DEFAULT_INFO_COLOR);
}

export {
  snackbarSuccessPayload,
  snackbarMapError,
  showSuccessSnackbar,
  showErrorSnackbar,
  showWarningSnackbar,
  showInfoSnackbar,
};
