import { axiosClient } from "@/api/axios";

export function getInfo(id) {
  return axiosClient.get(`course-overview/course-info/${id}`);
}

export function enroll(payload) {
  return axiosClient.post(`course-overview/enroll`, payload);
}

export function removeFavourite(payload) {
  return axiosClient.post(`course-overview/remove-favourite`, payload);
}

export function getStudentsInfo(id) {
  return axiosClient.get(`course-overview/students-info/${id}`);
}
