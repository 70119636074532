import { PostApi } from "@/api";
import { showSuccessSnackbar } from "@/support/snackbar";

export async function insertAnswer(data) {
  const response = await PostApi.insert(data);
  showSuccessSnackbar({
    code: response.status,
    message: `Answer Inserted Correctly`,
  });
}

export async function insertQuestion(data) {
  const response = await PostApi.insert(data);
  showSuccessSnackbar({
    code: response.status,
    message: `Question Inserted Correctly`,
  });
}
