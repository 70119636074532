import { Role } from "@/enums";

const getDefaultState = () => {
  return {
    userIsLogged: false,
    userId: null,
    userRole: null,
    userCC: null,
    userToken: null,
    oauthToken: null,
    userInfo: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    userIsLogged: (state) => {
      return state.userIsLogged;
    },
    userId: (state) => {
      return state.userId;
    },
    userRole: (state) => {
      return state.userRole;
    },
    userCC: (state) => {
      return state.userCC;
    },
    isAdmin: (state) => {
      return state.userRole && state.userRole == Role.codeToLabel(Role.ADMIN);
    },
    authorization: (state) => {
      return {
        headers: {
          Authorization: `Bearer ${state.userToken}`,
        },
      };
    },
    getUserFirsName: (state) => {
      return state.userInfo.name;
    },
    userName: (state) => {
      return `${state.userInfo.name} ${state.userInfo.surname}`;
    },
    userMail: (state) => {
      return `${state.userInfo.email}`;
    },
    initials: (state) => {
      return (
        state.userInfo.name.substring(0, 1) +
        state.userInfo.surname.substring(0, 1)
      );
    },
    getOauthToken(state) {
      return state.oauthToken;
    },
  },
  mutations: {
    SET_USER_IS_LOGGED(state, bool) {
      state.userIsLogged = bool;
    },
    SET_USER_DATA(state, userData) {
      if (userData) {
        state.userId = userData.id;
        state.userRole = userData.role;
        state.userToken = userData.token;
        state.userInfo = userData.info;
      } else {
        state.userId = null;
        state.userInfo = null;
      }
    },
    _setUserCC(state, cc) {
      state.userCC = cc;
    },
    SET_OAUTH_TOKEN(state, oauthToken) {
      state.oauthToken = oauthToken;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setUserData({ commit }, payload) {
      commit("SET_USER_DATA", payload);
    },
    setUserIsLogged({ commit }, payload) {
      commit("SET_USER_IS_LOGGED", payload);
    },
    setOauthToken({ commit }, payload) {
      commit("SET_OAUTH_TOKEN", payload);
    },
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
