import { SemestersService } from "@/services";

const getDefaultState = () => {
  return {
    semesters: [],
    currentSemester: {
      id: null,
      label: null,
    },
    selectedSemester: {
      id: null,
      label: null,
    },
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getSemesters: (state) => {
      return state.semesters;
    },
    getCurrentSemester: (state) => {
      return state.currentSemester;
    },
    getSelectedSemester: (state) => {
      return state.selectedSemester;
    },
  },
  mutations: {
    SET_SEMESTERS(state, payload) {
      state.semesters = payload;
    },
    SET_CURRENT_SEMESTER(state, payload) {
      state.currentSemester = payload;
    },
    SET_SELECTED_SEMESTER(state, payload) {
      state.selectedSemester = payload;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async fetchSemesters({ commit }) {
      try {
        const data = await SemestersService.getSemesters();
        commit("SET_SEMESTERS", data.semesters);
        commit("SET_CURRENT_SEMESTER", data.semesterDto);
        commit("SET_SELECTED_SEMESTER", data.semesterDto);
      } catch (error) {
        console.log(error);
      }
    },
    setSemesters({ commit }, payload) {
      commit("SET_SEMESTERS", payload);
    },
    setCurrentSemester({ commit }, payload) {
      commit("SET_CURRENT_SEMESTER", payload);
    },
    setSelectedSemester({ commit }, payload) {
      commit("SET_SELECTED_SEMESTER", payload);
    },
    setDefualtState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
