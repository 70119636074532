import { YourLearningApi } from "@/api";

export async function get(semesterId = null) {
  let query = {};
  if (semesterId) {
    query = {
      params: { semester: semesterId },
    };
  }
  const { data } = await YourLearningApi.get(query);
  return data;
}
