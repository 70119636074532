import { CourseOverviewApi } from "@/api";
import { showSuccessSnackbar } from "@/support/snackbar";

export async function getInfo(id) {
  const { data } = await CourseOverviewApi.getInfo(id);
  return data;
}

export async function enroll(payload, snackBarMessage) {
  const response = await CourseOverviewApi.enroll(payload);
  showSuccessSnackbar({ code: response.status, message: snackBarMessage });
}

export async function removeFavourite(payload) {
  const response = await CourseOverviewApi.removeFavourite(payload);
  showSuccessSnackbar({
    code: response.status,
    message: `Successfully removed favourite`,
  });
}

export async function getStudentsInfo(id) {
  const { data } = await CourseOverviewApi.getStudentsInfo(id);
  return data;
}
