import { axiosClient } from "@/api/axios";

export function login(payload) {
  return axiosClient.post("auth/login", payload);
}

export function getOauthConfig() {
  return axiosClient.get("auth/oauth/config");
}

export function validateOauthToken(token) {
  return axiosClient.post("auth/oauth/validate-token", token);
}
