import { TestApi } from "@/api";

export async function get(id) {
  const { data } = await TestApi.get(id);
  return data;
}

export async function insert(courseId, payload) {
  const { data } = await TestApi.insert(courseId, payload);
  return data;
}

export async function submit(id, test) {
  const { data } = await TestApi.submit(id, test);
  return data;
}
