const getDefaultState = () => {
  return {
    qaTab: 0,
    courseTab: 1,
    teamTab: 0,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getQaTab: (state) => {
      return state.qaTab;
    },
    getCourseTab: (state) => {
      return state.courseTab;
    },
    getTeamTab: (state) => {
      return state.teamTab;
    },
  },
  mutations: {
    _setQaTab(state, tab) {
      state.qaTab = tab;
    },
    _setCourseTab(state, tab) {
      state.courseTab = tab;
    },
    _setTeamTab(state, tab) {
      state.teamTab = tab;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
