import { DocumentationApi } from "@/api";

export async function getByUserId(userId, semesterId = null) {
  let query = {};
  if (semesterId) {
    query = {
      params: { semester: semesterId },
    };
  }
  const { data } = await DocumentationApi.getByUserId(userId, query);
  return data;
}

export async function getPreSignedUrl(fileId) {
  const { data } = await DocumentationApi.preSignedUrl(fileId);
  return data;
}
