import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import UserModule from "./modules/user";
import TabModule from "./modules/tabsPosition";
import CourseModule from "./modules/course";
import NavigationModule from "./modules/navigation";
import SemestersModule from "./modules/semesters";
import SnackbarModule from "./modules/snackbar";

Vue.use(Vuex);

const storeModules = {
  user: UserModule,
  tab: TabModule,
  course: CourseModule,
  navigation: NavigationModule,
  semesters: SemestersModule,
  snackbar: SnackbarModule,
};

const storeOptions = {
  state: {
    isDataLoaded: false,
  },
  getters: {
    isDataLoaded(state) {
      return state.isDataLoaded;
    },
  },
  mutations: {
    SET_IS_DATA_LOADED(state, bool) {
      state.isDataLoaded = bool;
    },
  },
  actions: {
    setIsDataLoaded({ commit }, payload) {
      commit("SET_IS_DATA_LOADED", payload);
    },
  },
  plugins: [
    createPersistedState({
      paths: ["user", "tab"],
    }),
  ],
  modules: storeModules,
};

export default new Vuex.Store(storeOptions);
