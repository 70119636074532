import { AllTopicsApi } from "@/api";

export async function getFrequented() {
  const { data } = await AllTopicsApi.getFrequented();
  return data;
}

export async function getTaught() {
  const { data } = await AllTopicsApi.getTaught();
  return data;
}

export async function getTeam() {
  const { data } = await AllTopicsApi.getTeam();
  return data;
}

export async function getFrequentedCourse() {
  const { data } = await AllTopicsApi.getFrequentedCourse();
  return data;
}

export async function getTaughtCourses() {
  const { data } = await AllTopicsApi.getTaughtCourses();
  return data;
}

export async function getTeamCourses() {
  const { data } = await AllTopicsApi.getTeamCourses();
  return data;
}
