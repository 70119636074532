import { EmailApi } from "@/api";
import { showSuccessSnackbar } from "@/support/snackbar";

export async function sendMailToUser(payload) {
  const response = await EmailApi.sendMailToUser(payload);
  showSuccessSnackbar({
    code: response.status,
    message: `Email has been sent correctly`,
  });
}

export async function sendProblemReport(payload) {
  const response = await EmailApi.sendProblemReport(payload);
  showSuccessSnackbar({
    code: response.status,
    message: "Email sent, thank you!",
  });
}
