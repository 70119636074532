<template>
  <v-breadcrumbs :large="isLessonView" :items="breadcrumbsItems">
    <template v-if="isLessonView" #item="{ item }">
      <v-breadcrumbs-item class="font-weight-bold">
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("navigation", {
      getDisplayItems: "getDisplayItems",
    }),
    ...mapGetters("course", {
      getCourseName: "getCourseName",
      getCourseProgress: "getCourseProgress",
    }),
    isLessonView() {
      return this.$route.name === "lesson";
    },
    breadcrumbsItems() {
      if (this.isLessonView) {
        return [
          {
            text: this.getCourseName,
            disabled: true,
            exact: true,
            divider: "/",
            to: {
              name: "dashboard",
            },
          },
        ];
      }
      return this.getDisplayItems;
    },
  },
};
</script>
