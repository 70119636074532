import { axiosClient } from "@/api/axios";

export function UploadRequest(request) {
  return axiosClient.post("files/multipart-upload-request", request);
}

export function UploadPart(payload) {
  return axiosClient.post("files/upload-part", payload);
}

export function UploadRegister(resource) {
  return axiosClient.post("files/register", resource);
}
