import { axiosClient } from "@/api/axios";

export function getAttempts(id) {
  return axiosClient.get(`video/${id}/attempts`);
}

export function getVideo(id) {
  return axiosClient.get(`video/${id}`);
}

export function getVideoPreSignedUrl(id, videoId) {
  return axiosClient.get(`video/${id}/asset/${videoId}/pre-signed-url`);
}
