const getDefaultState = () => {
  return {
    active: false,
    timeout: undefined,
    color: undefined,
    message: undefined,
    code: undefined,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    active: (state) => {
      return state.active;
    },
    timeout: (state) => {
      return state.timeout;
    },
    color: (state) => {
      return state.color;
    },
    message: (state) => {
      return state.message;
    },
    code: (state) => {
      return state.code;
    },
    text: (state) => {
      return state.code ? `${state.code} - ${state.message}` : state.message;
    },
  },
  mutations: {
    SET_ACTIVE(state, payload) {
      state.active = payload;
    },
    SET_TIMEOUT(state, payload) {
      state.timeout = payload;
    },
    SET_COLOR(state, payload) {
      state.color = payload;
    },
    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
    SET_CODE(state, payload) {
      state.code = payload;
    },
  },
  actions: {
    showSnackbar({ commit }, payload) {
      commit("SET_ACTIVE", true);
      commit("SET_TIMEOUT", payload.timeout);
      commit("SET_COLOR", payload.color);
      commit("SET_MESSAGE", payload.message);
      commit("SET_CODE", payload.code);
    },
    setActive({ commit }, payload) {
      commit("SET_ACTIVE", payload);
    },
  },
};
