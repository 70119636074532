import { FileApi } from "@/api";

export async function UploadRequest(request) {
  const { data } = await FileApi.UploadRequest(request);
  return data;
}

export async function UploadPart(payload) {
  await FileApi.UploadPart(payload);
}

export async function UpdateRegister(resource) {
  const { data } = await FileApi.UploadRegister(resource);
  return data;
}
