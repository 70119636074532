import { AuthApi } from "@/api";

export async function login(username, password) {
  const payload = {
    username,
    password,
  };
  const response = await AuthApi.login(payload);
  return response;
}

export async function getOauthConfig() {
  const { data } = await AuthApi.getOauthConfig();
  return data;
}

export async function validateOauthToken(token) {
  const response = await AuthApi.validateOauthToken(token);
  return response;
}
