<template>
  <div
    class="relative w-100 vh-100 d-flex align-center justify-center"
    style="z-index: 5; background-color: #f4f6f9"
  >
    <Icon icon="svg-spinners:pulse-rings-3" color="#ec9436" width="100" />
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
export default {
  name: "PageLoader",
  components: {
    Icon,
  },
};
</script>
