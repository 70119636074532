import { DashboardAdminApi } from "@/api";

export async function getAdminUserInfo() {
  const { data } = await DashboardAdminApi.getAdminUserInfo();
  return data;
}

export async function getAdminLmsInfo() {
  const { data } = await DashboardAdminApi.getAdminLmsInfo();
  return data;
}

export async function getAdminRankingInfo() {
  const { data } = await DashboardAdminApi.getAdminRankingInfo();
  return data;
}
export async function getAdminSemesterInfo() {
  const { data } = await DashboardAdminApi.getAdminSemesterInfo();
  return data;
}
