const getDefaultState = () => {
  return {
    courseName: undefined,
    courseProgress: undefined,
    bookmarks: {
      current: {
        lesson: undefined,
        time: undefined,
      },
      last: {
        lesson: undefined,
        time: undefined,
      },
    },
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getCourseName: (state) => {
      return state.courseName;
    },
    getCourseProgress: (state) => {
      return state.courseProgress;
    },
    getCurrentBookmark: (state) => {
      return state.bookmarks.current;
    },
    getLastBookmark: (state) => {
      return state.bookmarks.last;
    },
    isLastBeforeCurrent: (state, getters) => {
      const current = state.bookmarks.current;
      const last = state.bookmarks.last;

      return getters.isBefore(last, current);
    },
    isBefore: () => (bookmarkOne, bookmarkTwo) => {
      if (bookmarkOne.lesson < bookmarkTwo.lesson) return true;
      if (
        bookmarkOne.lesson == bookmarkTwo.lesson &&
        bookmarkOne.time < bookmarkTwo.time
      )
        return true;

      return false;
    },
  },
  mutations: {
    SET_COURSE_NAME(state, courseName) {
      state.courseName = courseName;
    },
    SET_COURSE_PROGRESS(state, courseProgress) {
      state.courseProgress = courseProgress;
    },
    SET_CURRENT_LESSON(state, payload) {
      state.bookmarks.current.lesson = payload;
    },
    SET_CURRENT_TIME(state, payload) {
      state.bookmarks.current.time = payload;
    },
    SET_LAST_LESSON(state, payload) {
      state.bookmarks.last.lesson = payload;
    },
    SET_LAST_TIME(state, payload) {
      state.bookmarks.last.time = payload;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setCourseName({ commit }, payload) {
      commit("SET_COURSE_NAME", payload);
    },
    setCourseProgress({ commit }, payload) {
      commit("SET_COURSE_PROGRESS", payload);
    },
    resetCourse({ commit }) {
      commit("SET_COURSE_NAME", undefined);
      commit("SET_COURSE_PROGRESS", undefined);
    },
    setCurrentLesson({ commit }, payload) {
      commit("SET_CURRENT_LESSON", payload);
    },
    setCurrentTime({ commit }, payload) {
      commit("SET_CURRENT_TIME", payload);
    },
    setLastLesson({ commit }, payload) {
      commit("SET_LAST_LESSON", payload);
    },
    setLastTime({ commit }, payload) {
      commit("SET_LAST_TIME", payload);
    },
    setCurrentBookmark({ commit }, payload) {
      commit("SET_CURRENT_LESSON", payload.lesson);
      commit("SET_CURRENT_TIME", payload.time);
    },
    setLastBookmark({ commit }, payload) {
      commit("SET_LAST_LESSON", payload.lesson);
      commit("SET_LAST_TIME", payload.time);
    },
    updateBookmarks({ commit, getters }, payload) {
      commit("SET_CURRENT_LESSON", payload.lesson);
      commit("SET_CURRENT_TIME", payload.time);

      if (getters.isLastBeforeCurrent) {
        commit("SET_LAST_LESSON", payload.lesson);
        commit("SET_LAST_TIME", payload.time);
      }
    },
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
