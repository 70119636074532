import Utils from "@/support/utils.js";
import { showSuccessSnackbar } from "@/support/snackbar";

export function downloadFile(
  data,
  snackbarMessage = "File has been correctly downloaded"
) {
  const blob = Utils.base64ToBlob(data.file, "application/octet-stream");
  let url = URL.createObjectURL(blob);
  createDownloadElement(url, data.name);
  showSuccessSnackbar({ message: snackbarMessage });
}

export function downloadFileFromUrl(url, name) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      createDownloadElement(url, name);
    });
}

function createDownloadElement(url, name) {
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  link.click();
  link.remove();
}
