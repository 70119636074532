import { axiosClient } from "@/api/axios";

export async function getCourses(semesterId) {
  return axiosClient.get(`team/${semesterId}/courses`);
}

export async function getStudents(semesterId) {
  return axiosClient.get(`team/${semesterId}/students`);
}

export async function getTeachers(semesterId) {
  return axiosClient.get(`team/${semesterId}/teachers`);
}
