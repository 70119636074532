import { VideoApi } from "@/api";

export async function getAttempts(id) {
  const { data } = await VideoApi.getAttempts(id);
  return data;
}

export async function getVideo(id) {
  const { data } = await VideoApi.getVideo(id);
  return data;
}

export async function getVideoPreSignedUrl(id, videoId) {
  const { data } = await VideoApi.getVideoPreSignedUrl(id, videoId);
  return data;
}
