import { TeamApi } from "@/api";

export async function getCourses(semesterId) {
  const { data } = await TeamApi.getCourses(semesterId);
  return data;
}

export async function getStudents(semesterId) {
  const { data } = await TeamApi.getStudents(semesterId);
  return data;
}

export async function getTeachers(semesterId) {
  const { data } = await TeamApi.getTeachers(semesterId);
  return data;
}
