function enumToSelectOptions(codes, labels, from, to) {
  let options = [];
  for (var i = 0; i < codes.length; i++) {
    let code = codes[i];
    let label = labels[i];
    if (from && code < from) continue;
    if (to && code >= to) continue;
    options.push({ value: code, text: label });
  }
  return options;
}

function enumCodeToLabel(codes, labels, code) {
  for (var i = 0; i < codes.length; i++) {
    if (codes[i] == code) return labels[i];
  }
  return "";
}

export class Status {
  static SUSPENDED = 1;
  static ENTITLED = 2;

  static Codes = [Status.SUSPENDED, Status.ENTITLED];
  static Labels = ["Suspended", "Entitled"];

  static toSelectOptions(from, to) {
    return enumToSelectOptions(Status.Codes, Status.Labels, from, to);
  }

  static codeToLabel(code) {
    return enumCodeToLabel(Status.Codes, Status.Labels, code);
  }
}

export class Role {
  static NONE = 0;
  static ROOT = 1;
  static ADMIN = 2;
  static USER = 4;
  static TEACHER = 8;
  static MANAGER = 16;

  static Codes = [
    Role.NONE,
    Role.ROOT,
    Role.ADMIN,
    Role.USER,
    Role.TEACHER,
    Role.MANAGER,
  ];
  static Labels = ["NONE", "ROOT", "ADMIN", "USER", "TEACHER", "MANAGER"];

  static toSelectOptions(from, to) {
    return enumToSelectOptions(Role.Codes, Role.Labels, from, to);
  }

  static codeToLabel(code) {
    return enumCodeToLabel(Role.Codes, Role.Labels, code);
  }
}

export class Seniority {
  static JUNIOR = 1;
  static SENIOR = 2;

  static Codes = [Seniority.JUNIOR, Seniority.SENIOR];
  static Labels = ["Junior", "Senior"];

  static toSelectOptions(from, to) {
    return enumToSelectOptions(Seniority.Codes, Seniority.Labels, from, to);
  }

  static codeToLabel(code) {
    return enumCodeToLabel(Seniority.Codes, Seniority.Labels, code);
  }
}

export class Level {
  static BASIC = 1;
  static ADVANCED = 2;

  static Codes = [Level.BASIC, Level.ADVANCED];
  static Labels = ["Basic", "Advanced"];

  static toSelectOptions(from, to) {
    return enumToSelectOptions(Level.Codes, Level.Labels, from, to);
  }

  static codeToLabel(code) {
    return enumCodeToLabel(Level.Codes, Level.Labels, code);
  }
}
