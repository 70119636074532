import { DashboardStudentApi } from "@/api";

export async function getStudentUserInfo() {
  const { data } = await DashboardStudentApi.getStudentUserInfo();
  return data;
}

export async function getStudentProgressInfo() {
  const { data } = await DashboardStudentApi.getStudentProgressInfo();
  return data;
}

export async function getStudentSemesterInfo() {
  const { data } = await DashboardStudentApi.getStudentSemesterInfo();
  return data;
}

export async function getStudentLatestTopics() {
  const { data } = await DashboardStudentApi.getStudentLatestTopics();
  return data;
}

export async function getStudentCoursesInfo() {
  const { data } = await DashboardStudentApi.getStudentCoursesInfo();
  return data;
}

export async function getStudentRankingInfo() {
  const { data } = await DashboardStudentApi.getStudentRankingInfo();
  return data;
}
