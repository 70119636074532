import { SemesterApi } from "@/api";

export async function getSemesters() {
  const { data } = await SemesterApi.getSemesters();
  return data;
}

export async function getAcademyYears() {
  const { data } = await SemesterApi.getAcademyYears();
  return data;
}
