import { axiosClient } from "@/api/axios";

export function getFrequented() {
  return axiosClient.get("topics/frequented");
}
export function getTaught() {
  return axiosClient.get("topics/taught");
}
export function getTeam() {
  return axiosClient.get("topics/team");
}
export function getFrequentedCourse() {
  return axiosClient.get("topics/frequented-courses");
}
export function getTaughtCourses() {
  return axiosClient.get("topics/taught-courses");
}
export function getTeamCourses() {
  return axiosClient.get("topics/team-courses");
}
