import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store/index.js";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  let userIsLogged = Store.state.user.userIsLogged;
  if (to.meta.requiresAuth && !userIsLogged) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
