<template>
  <v-snackbar v-model="show" :timeout="timeout" :color="color">
    {{ text }}
    <template #action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TheSnackbar",
  computed: {
    ...mapGetters("snackbar", {
      active: "active",
      timeout: "timeout",
      color: "color",
      text: "text",
    }),
    show: {
      get() {
        return this.active;
      },
      set(value) {
        this.setActive(value);
      },
    },
  },
  methods: {
    ...mapActions("snackbar", {
      setActive: "setActive",
    }),
  },
};
</script>
