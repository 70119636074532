import { axiosClient } from "@/api/axios";

export function getAllUsers() {
  return axiosClient.get("users");
}

export function getSingleUser(userId, semesterId) {
  return axiosClient.get(`users/${userId}?semester=${semesterId}`);
}

export function deleteAllUserLesson(userId) {
  return axiosClient.delete(`users/${userId}/lessons/delete-all`);
}

export function getUserRoles() {
  return axiosClient.get(`users/roles`);
}

export function updateUser(userId, editedData) {
  return axiosClient.post(`users/${userId}/edit`, editedData);
}

export function deleteUserSingleLesson(userId, courseInstanceId) {
  return axiosClient.delete(
    `users/${userId}/lessons/${courseInstanceId}/delete`
  );
}
