const getDefaultState = () => {
  return {
    fullHistoryItems: [],
    displayItems: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getFullHistoryItems: (state) => {
      return state.fullHistoryItems;
    },
    getDisplayItems: (state) => {
      return state.displayItems;
    },
  },
  mutations: {
    SET_FULL_HISTORY_ITEMS(state, payload) {
      state.fullHistoryItems = payload;
    },
    SET_DISPLAY_ITEMS(state, payload) {
      state.displayItems = payload;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setFullHistoryItems({ commit, state }, payload) {
      payload.disabled = true;
      let fullHistoryItems = state.fullHistoryItems;

      fullHistoryItems.forEach((e, index) => {
        e.disabled = false;
        if (e.id === payload.id) fullHistoryItems.length = index;
      });

      fullHistoryItems.push(payload);

      let displayItems = fullHistoryItems.slice(-4);

      commit("SET_FULL_HISTORY_ITEMS", fullHistoryItems);
      commit("SET_DISPLAY_ITEMS", displayItems);
    },
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
