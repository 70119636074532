import { axiosClient } from "@/api/axios";

export function getLesson(id) {
  return axiosClient.get(`lessons/${id}`);
}

export function getAllLessons() {
  return axiosClient.get(`lessons`);
}

export function getLessonInfo(lessonId) {
  return axiosClient.get(`lessons/${lessonId}/info`);
}

export function saveLessonInfo(lessonId, lessonInfo) {
  return axiosClient.post(`lessons/${lessonId}/info/save`, lessonInfo);
}

export function deleteLesson(lessonId) {
  return axiosClient.delete(`lessons/${lessonId}/delete`);
}

export function getQuestions(lessonId) {
  return axiosClient.get(`lessons/${lessonId}/questions`);
}

export async function saveQuestions(lessonId, questions) {
  return axiosClient.post(`lessons/${lessonId}/questions/save`, questions);
}
