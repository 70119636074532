<template>
  <v-app-bar app flat class="bc-white iason-border-bottom">
    <HeaderBreadcrumbs />
    <v-spacer />
    <v-col v-if="showProgressBar" cols="3">
      <HeaderProgressBar :progress="getCourseProgress" />
    </v-col>
    <v-spacer />
    <v-col v-if="showSemesterSelection" cols="2">
      <HeaderSemesterSelect />
    </v-col>
    <div class="d-flex align-center">
      <v-avatar color="#233642" size="40">
        <span class="white--text">{{ initials }}</span>
      </v-avatar>
      <v-btn color="#233642" icon class="ml-2" @click="logout">
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HeaderProgressBar from "./header/HeaderProgressBar.vue";
import HeaderSemesterSelect from "./header/HeaderSemesterSelect.vue";
import HeaderBreadcrumbs from "./header/HeaderBreadcrumbs.vue";

export default {
  name: "TheHeader",
  components: {
    HeaderProgressBar,
    HeaderSemesterSelect,
    HeaderBreadcrumbs,
  },
  data() {
    return {
      navigatorItems: [],
    };
  },
  computed: {
    ...mapGetters("user", {
      userRole: "userRole",
      initials: "initials",
    }),
    ...mapGetters("course", {
      getCourseProgress: "getCourseProgress",
    }),
    showSemesterSelection() {
      return (
        this.$route.meta.semesterSelection &&
        this.$route.meta.semesterSelection.includes(this.userRole)
      );
    },
    showProgressBar() {
      if (["MANAGER", "ADMIN"].includes(this.userRole)) return false;
      return (
        this.getCourseProgress != -1 &&
        this.getCourseProgress != undefined &&
        this.isLessonView
      );
    },
    isLessonView() {
      return this.$route.name === "lesson";
    },
  },
  created: async function () {
    await this.loadSemesters();
  },
  methods: {
    ...mapActions({
      setUserDefaultState: "user/setDefaultState",
      setNavigationDefaultState: "navigation/setDefaultState",
      fetchSemesters: "semesters/fetchSemesters",
    }),
    async loadSemesters() {
      await this.fetchSemesters();
    },
    logout() {
      this.setUserDefaultState();
      this.setNavigationDefaultState();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
