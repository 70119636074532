import Axios from "axios";
import Store from "@/store";
import router from "@/router";
import { snackbarMapError, showErrorSnackbar } from "@/support/snackbar";

const axiosConfig = {
  baseURL: `/v1/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 30000,
};

const axiosClient = Axios.create(axiosConfig);

axiosClient.interceptors.request.use((config) => {
  const token = Store.state.user.userToken;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorPayload = snackbarMapError(error);
    if (errorPayload.code === 401) {
      await Store.dispatch("user/setDefaultState");
      await Store.dispatch("test/setDefaultState");
      await router.push({ name: "login" });
    }
    if (errorPayload.code === 403) {
      await router.push({ name: "dashboard" });
    }
    showErrorSnackbar({
      code: errorPayload.code,
      message: errorPayload.message,
    });
    return Promise.reject(error);
  }
);

export { axiosClient };
