import { axiosClient } from "@/api/axios";

export function getStudents() {
  return axiosClient.get("enroll-students/students");
}

export function getCourses() {
  return axiosClient.get("enroll-students/courses");
}

export function getSemesters() {
  return axiosClient.get("enroll-students/semesters");
}

export function enrollStudents(data) {
  return axiosClient.post(`enroll-students/enroll`, data);
}
