import { EnrollStudentApi } from "@/api";
import { showSuccessSnackbar } from "@/support/snackbar";

export async function getStudents() {
  const { data } = await EnrollStudentApi.getStudents();
  return data;
}

export async function getCourses() {
  const { data } = await EnrollStudentApi.getCourses();
  return data;
}

export async function getSemesters() {
  const { data } = await EnrollStudentApi.getSemesters();
  return data;
}

export async function enrollStudents(data) {
  const response = await EnrollStudentApi.enrollStudents(data);
  showSuccessSnackbar({
    code: response.status,
    message: `The Enrollment Have Been Correctly Executed`,
  });
  return response;
}
