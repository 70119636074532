import { axiosClient } from "@/api/axios";

export function get(id) {
  return axiosClient.get(`test/${id}/info`);
}

export function insert(courseId, payload) {
  return axiosClient.post(`test/${courseId}`, payload);
}

export function submit(id, test) {
  return axiosClient.post(`test/${id}/submit`, test);
}
