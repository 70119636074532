import { axiosClient } from "@/api/axios";

export function setProgress100(enrollmentId) {
  return axiosClient.post(
    `course-enrollments/${enrollmentId}/set-progress-full`
  );
}

export function resetTest(attemptId) {
  return axiosClient.post(`course-enrollments/reset-test`, attemptId);
}

export function switchStatus(enrollmentId) {
  return axiosClient.post(`course-enrollments/${enrollmentId}/switch-status`);
}

export async function update(enrollmentId, payload) {
  return axiosClient.post(`course-enrollments/${enrollmentId}/update`, payload);
}

export async function saveBookmarks(enrollmentId, payload) {
  return axiosClient.post(
    `course-enrollments/${enrollmentId}/save-bookmarks`,
    payload
  );
}
