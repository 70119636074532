export function progressColor(courseProgress) {
  if (courseProgress < 30) {
    return "error";
  } else if (courseProgress >= 30 && courseProgress < 70) {
    return "warning";
  } else if (courseProgress >= 70 && courseProgress < 100) {
    return "info";
  } else if (courseProgress == 100) {
    return "success";
  }
  return "gray";
}
