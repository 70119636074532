<template>
  <v-select
    v-model="semester"
    :items="semesterItems"
    label="Semester"
    dense
    hide-details
    return-object
    color="#ec9436"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HeaderSemesterSelect",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("semesters", {
      getSemesters: "getSemesters",
      getSelectedSemester: "getSelectedSemester",
    }),
    semesterItems() {
      return this.getSemesters || [];
    },
    semester: {
      get() {
        return this.getSelectedSemester;
      },
      set(value) {
        this.setSelectedSemester(value);
      },
    },
  },
  methods: {
    ...mapActions("semesters", {
      setSelectedSemester: "setSelectedSemester",
    }),
  },
};
</script>
