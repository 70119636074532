import { axiosClient } from "@/api/axios";

export function getAdminUserInfo() {
  return axiosClient.get("/dashboard/admin/user-info");
}

export function getAdminLmsInfo() {
  return axiosClient.get("/dashboard/admin/lms-info");
}

export function getAdminRankingInfo() {
  return axiosClient.get("/dashboard/admin/ranking-info");
}

export function getAdminSemesterInfo() {
  return axiosClient.get("/dashboard/admin/semester-info");
}
