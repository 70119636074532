import { axiosClient } from "@/api/axios";

export function exportLessons() {
  return axiosClient.get("export/excel/lessons");
}

export function exportStudentsReport(year, onlyActive) {
  return axiosClient.get(
    `export/excel/students/report/${year}?only-active=${onlyActive}`
  );
}

export function getSemesterInfo(selectedSemester, onlyActive) {
  return axiosClient.get(
    `export/excel/semesters/${selectedSemester}?only-active=${onlyActive}`
  );
}

export function exportStudyPlan() {
  return axiosClient.get(`export/excel/study-plan/report`);
}
