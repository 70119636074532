import { ExportApi } from "@/api";
import { downloadFile } from "@/support/file";

export async function exportLessons() {
  const { data } = await ExportApi.exportLessons();
  downloadFile(data, "Lessons report has been correctly generated");
}

export async function exportStudentsReport(year, onlyActive) {
  const { data } = await ExportApi.exportStudentsReport(year, onlyActive);
  downloadFile(data, "Students report has been correctly generated");
}

export async function getSemesterInfo(selectedSemester, onlyActive) {
  const { data } = await ExportApi.getSemesterInfo(
    selectedSemester,
    onlyActive
  );
  downloadFile(data, "Semester report has been correctly generated");
}

export async function exportStudyPlan() {
  const { data } = await ExportApi.exportStudyPlan();
  downloadFile(data, "Study plan report has been correctly generated");
}
