<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" class="d-flex justify-space-between py-0">
          <span class="c-darkBlue font-15 font-weight-medium"> Progress: </span>
          <span class="c-darkBlue font-15 font-weight-medium">
            {{ progress + "%" }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-progress-linear
            class="w-100"
            :color="progressBarColor"
            buffer-value="100"
            :value="progress"
            height="5"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { progressColor } from "@/support/colors";

export default {
  props: {
    progress: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    progressBarColor() {
      return progressColor(this.progress);
    },
  },
};
</script>
